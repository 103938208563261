@keyframes runline {
  to {
    transform: translateX(-100%);
  }
}

.remainder {
  animation: balance-refresh 0.1s infinite;
}

@keyframes balance-refresh {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.99;
  }
  100% {
    opacity: 1;
  }
}
.btn,
.btnRegister {
  outline: none;
  border: 0;
  padding: 5px 20px;
  margin-left: 25px;
  border-radius: 10px;
  font-size: 0.875rem;
  vertical-align: middle;
  border-radius: 4px;
  font-weight: 500;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
.btnRegister {
  margin-left: 0px !important;
}
.btnActive {
  background: #005ab3;
  color: #fff;
  cursor: pointer;
  padding: 5px 20px;
  outline: none;
  border: none;
  font-size: 0.875rem;
  vertical-align: middle;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  &:hover {
    box-shadow: none;
    background: #115fad;
  }
  a {
    list-style: none;
  }
}
.btnActiveBig {
  padding: 10px 50px !important;
  font-weight: 500;
}
.btnLink {
  text-decoration: none;
  color: #000;
  transition: 0.1s;
}
.btnLink:hover {
  font-weight: 500;
}

@media (max-width: 1600px) {
  .balanceBox {
    width: 80% !important;
  }

  .rowContentName {
    display: block;
    width: 13vw;
    white-space: nowrap;
    overflow: hidden;
  }

  .rowContentName .scrollable {
    padding-left: 100%;
    display: inline-block;
    white-space: nowrap;
    animation: runline 8s linear infinite 1s both;
  }

  .rowContentName:hover .scrollable {
    animation-play-state: paused;
  }
}

@media (max-width: 1400px) {
  .balanceBox {
    padding: 2px !important;
    justify-content: center !important;
  }

  .balanceContainer {
    height: 50px !important;
  }

  .topFinalBox {
    position: static !important;
    margin-right: 10px;
    height: 25px !important;
  }

  .plusImage {
    height: 18px !important;
    width: 18px !important;
  }

  .rowContent {
    min-height: 25px;
    width: 140px !important;
    flex-direction: column !important;
    justify-content: center !important;
    /* align-items: center !important; */
  }

  .rowName {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 900px) {
  .rowContent {
    width: 110px !important;
  }
}

@media (max-width: 600px) {
  .list {
    grid-template-columns: repeat(5, 1fr) !important;
    padding-left: 0 !important;
  }

  .head {
    margin-top: 45px !important;
    font-size: 1.2rem !important;
  }

  .rowContent {
    width: 65px !important;
  }

  .rowFooterMobile {
    width: 50% !important;
  }

  .minusImage {
    height: 5px !important;
    width: 18px !important;
  }

  .popUpRegistration {
    width: 85% !important;
    height: 500px;
    overflow-y: auto;
  }

  .mobileGroup {
    font-size: 14px !important;
  }

  .remainder {
    margin-left: 0 !important;
  }

  .circleButton {
    width: 40px !important;
    height: 40px !important;
    font-size: 1rem !important;
  }
}

@media (max-width: 400px) {
  .balanceBox {
    width: 95% !important;
  }

  .rowContent {
    width: 15vw !important;
  }

  .rowFooterMobile {
    width: 70% !important;
  }

  .remainderBox p {
    font-size: 1rem !important;
  }
}

@media (max-height: 500px) {
  .popUpRegistration {
    height: 100%;
    overflow: auto;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("../src/Assets/Images/задний фон.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
  background-color: #f3f5f8;
}

img {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.registration {
  margin-top: 50px;
}

.regItem {
  white-space: initial !important;
}

.balanceContainer {
  height: 100px;
}

.balanceBox {
  position: fixed;
  top: 10px;
  left: 50%;
  width: 55%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: solid 2.5px black;
}

.remainderBox {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.remainder p {
  align-items: center;
  line-height: 0 !important;
}

.remainder {
  text-align: center;
  align-items: center;
}

.topFinalBox {
  margin-left: 30px;
}

.description {
  margin-top: 40px;
}

.circleButtonBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: solid 1px #3b72b8;
  border-radius: 10px;
  padding: 5px;
  width: 60px;
}

.circleButtonBox span {
  border: solid 1px #3b72b8;
  border-radius: 10px;
  padding: 5px;
}

.circleButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: #3b72b8;
  border: solid 2.5px black;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 80ms;
}

.circleButton:hover {
  background-color: rgb(87, 140, 209);
  scale: 1.05;
  font-size: 19px;
}

.plusImage {
  height: 12px;
  width: 12px;
}

.minusImage {
  height: 3px;
  width: 12px;
}

.head {
  margin-top: 50px;
}

.list {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 0.9fr);
  /* gap: 5px; */
}

.list > div:nth-child(6n + 1) {
  border: none;
  grid-column: 1 / -1;
}

.rowContent:nth-child(12n + 2),
.rowContent:nth-child(12n + 3),
.rowContent:nth-child(12n + 4),
.rowContent:nth-child(12n + 5),
.rowContent:nth-child(12n + 6) {
  background-color: #e3f1ff;
}

.rowContent:nth-child(12n + 8),
.rowContent:nth-child(12n + 9),
.rowContent:nth-child(12n + 10),
.rowContent:nth-child(12n + 11),
.rowContent:nth-child(12n + 12) {
  background-color: #80bfff;
  /* color: #f5f5f5; */
}

.rowFooterMobile {
  background-color: white;
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 30%;
  padding: 10px;
  border: solid 2.5px black;
  border-radius: 10px;
  z-index: 1200;
}

.closeBox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.popUp {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.popUpRegistration {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  border: solid 2.5px #000;
  z-index: 100;
  font-size: 18px;
  text-align: center;
}

.popUpRegistrationContent {
  margin-bottom: 20px;
}

.popUpRegistrationContent div {
  margin-top: 10px;
}

.rowContent {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ffff;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 13px;
  text-overflow: "ellipsis";
  padding: 0;
  cursor: pointer;
}

.rowHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.rowContentInfo {
  text-align: center;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

.rowName {
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.rowContentButtonBox {
  display: flex;
  flex-direction: column;
}

.squareButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b72b8;
  border: none;
  width: 20px;
  height: 21px;
  color: white;
  font-size: 8px;
  cursor: pointer;
  transition: 80ms;
}

.topRightButton {
  border-top-right-radius: 8px;
  border-bottom: solid 1px #000;
  border-left: solid 2px #000;
}

.bottomRightButton {
  border-top: solid 1px #000;
  border-bottom-right-radius: 8px;
  border-left: solid 2px #000;
}

.topLeftButton {
  border-top-left-radius: 8px;
  border-bottom: solid 1px #000;
  border-right: solid 2px #000;
}

.bottomLeftButton {
  border-top: solid 1px #000;
  border-bottom-left-radius: 8px;
  border-right: solid 2px #000;
}

.squareButton:hover {
  background-color: rgb(87, 140, 209);
  scale: 1.05;
  font-size: 10px;
}
