.WrapperPrize {
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PrizeHeader {
  width: 100%;
}

.PrizeBox {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  border-radius: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 30px;
  max-height: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.PrizeContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 17px;
  z-index: 1;
  transition: 0.25s;
  cursor: pointer;
  .URLButton {
    border: solid 1px #80bfff;
    color: #80bfff;
  }
}

.PrizeChosen {
  background-color: #005ab3;
  color: #fff;
}

.PrizeBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.PrizeContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px;
  border: solid 1px #005ab34d;
  border-radius: 16px;
}

.PrizePlace {
  display: flex;
  padding: 4px 10px 4px 10px;
  font-size: 13px;
  background-color: #b3cee9;
  color: #fff;
  border-radius: 6px;
  width: max-content;
}

.Prize {
  height: 100%;
}

.TechnoPark {
  background-color: #ffc265;
}

.URLButton {
  width: max-content;
  color: #005ab3;
  border: solid 1px #005ab3;
  border-radius: 4px;
  padding: 6px 7px 6px 7px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s;
  z-index: 2;
}

.URLButton:hover {
  background-color: #80bfff;
  color: #fff;
}

.ButtonFinishBox {
  width: 100%;
  margin-top: 20px;
}

.NextButton {
  width: max-content;
  border-radius: 4px;
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NextButton:hover {
  background-color: #80bfff;
  color: #fff;
}

.NotChosen {
  width: max-content;
  border-radius: 4px;
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  transition: 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.432);
  background-color: #fff;
  cursor: default;
}

.WrapperPopUp h1 {
  font-weight: 400;
}

.userID {
  margin: 0 auto;
  width: max-content;
  padding: 0 20px 0 20px;
  margin-bottom: 40px;
  border: solid 1px #d5dde4;
  border-radius: 12px;
}

.WrapperPopUp label {
  display: flex;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 95%;
}

.PrizeURLBox {
  display: flex;
  gap: 5px;
  border: solid 1px #d5dde4;
  border-radius: 6px;
  padding: 20px;
}

.PrizeURLBox span {
  color: #005ab3;
  cursor: pointer;
  transition: 0.25s;
}

.PrizeURLBox span:hover {
  color: #80bfff;
}

@media screen and (max-width: 1280px) {
  .ChartBox {
    display: flex;
    justify-content: center;
  }
  .pieChart {
    width: 80%;
    margin-left: 0;
  }
}

@media screen and (max-width: 760px) {
  h1 {
    font-size: 36px !important;
  }

  .WrapperPrize {
    padding: 20px;
  }
  .PrizeBox {
    width: auto;
    padding: 20px 16px 20px 16px;
    grid-template-columns: 1fr 1fr;
  }

  .ButtonBox {
    margin-top: 20px;
  }
}

@media screen and (max-width: 450px) {
  .PrizeBox {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-height: 800px) {
  .PrizeHeader h1 {
    margin: 10px 10px 10px 0;
  }

  .PrizeBox {
    margin-top: 10px;
    padding: 15px;
  }
}
