.WrapperCenterAnket {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.WrapperAnket {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  width: 60%;
  border-radius: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 10px;
  max-height: 100%;
  margin-bottom: 60px;
}

.WrapperAnket h1 {
  text-align: center;
}

.SelectorBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.AnketLine {
  margin-top: 20px;
  font-weight: 300;
}
.titleAnket {
  font-weight: 600;
}
.screenHide {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.5);
}
.wrapperBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .WrapperAnket {
    width: 80%;
  }

  .SelectorBox {
    flex-direction: column;
    gap: 0;
  }
}
