.Selector {
  width: 100%;
  position: relative;
}

.DataDefault {
  height: auto;
  outline: none;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
  overflow: hidden;
  appearance: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid rgba($color: #000000, $alpha: 0.35);
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  box-sizing: border-box;
  background: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 12.5px 14px;
  &:hover {
    border: 1px solid #297ad8;
  }
}

.wrapperListSelector {
  position: absolute;
  width: 100%;
  margin-top: -8px;
  z-index: 1;
  padding: 8px 0;
  border-radius: 8px;
  max-height: 50vh;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.wrapperListSelectorItem {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  vertical-align: middle;
  user-select: none;
  padding: 6px 16px;
  font-size: 0.95rem;
  &:hover {
    background: #f3f3f3;
  }
}

@media screen and (max-width: 760px) {
}
@media screen and (max-width: 450px) {
  .wrapperListSelector {
    max-height: 150px;
  }
}
